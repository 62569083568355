import Cookie from 'js-cookie';
import queryString from 'query-string';
import {showWaitingIcon,hideWaitingIcon} from './w1_helper_functions';
/**
 * Controls enable/disable of the submit button.
 * And display of the loading icon
 * @param enable
 */
export function submitButton(enable) {

  let submitButton = $('.gform_button[type="submit"]');
  let loadingIcon = $('<i class="fa fa-circle-o-notch fa-spin icon-loading"></i>');

  if (enable) {
    // do not reenable the submit button if more than 1 submission
    if (global.ccUserInfo.attemptsAtSubmission === 0) {
      submitButton.removeClass('disabled');
      submitButton.prop('disabled', false);
    }

    // hide loading icon
    if (submitButton.next().hasClass('icon-loading'))
      submitButton.next().hide();

  } else {
    submitButton.addClass('disabled');
    submitButton.prop('disabled', true);

    // show loading icon if exists
    if (submitButton.next().hasClass('icon-loading')) {
      //submitButton.next().show();
      submitButton.next().css('display', 'initial');
    // else append it
    } else {
      submitButton.after(loadingIcon);
    }
  }

}




export function submitForm(formID) {
  // replacing the input causes firefox to not submit the form later on line ~72: $('form.schedule-consultation-form').submit();
  //$('.gform_button[type="submit"]').replaceWith('<span class="gform_button button disabled btn-orange"><i class="fa fa-circle-o-notch fa-spin"></i> Confirm Consultation</span>');

  // this is the workaround
  // @todo: remove, using submitButton() now

  let submitButton = $('.gform_button[type="submit"]');
  submitButton.addClass('input-loading');
  submitButton.prop('disabled', true);

  let callCenterNumber = $('.trans_id').first().text() || '888-651-9950';

  if (typeof global.ccUserInfo.attemptsAtSubmission === 'undefined') {
    global.ccUserInfo.attemptsAtSubmission = 0;
  }

  global.ccUserInfo.attemptsAtSubmission++;

  // submitButton.slideUp();

  showWaitingIcon('Validating Your Appointment');

  // add Google Analytics to leadData

    if (typeof ga !== 'undefined') {
      ga(function () {
        const tracker = ga.getAll()[0];
        global.ga.trackingId = tracker.get('trackingId');
        global.ga.clientId = tracker.get('clientId');
        global.ga.userId = Cookie.get('userId');
        global.ga.gclId = Cookie.get('cc-gclid');
      });
    } else {
      if(global.ga != undefined){
        global.ga.trackingId = global.ga.clientId = global.ga.userId = global.ga.gclId = '';
      }
    }

  if(global.ccUserInfo.guid.indexOf("s:") >= 0){
    var guidRegex = new RegExp("([a-z]|[A-Z]|[0-9]){18}");
    var formattedGuidArray = global.ccUserInfo.guid.match(guidRegex);
    global.ccUserInfo.guid = formattedGuidArray[0];
  }


  const leadData = {
    action: 'cc_salesforce_validate_appointment',
    GUID: global.ccUserInfo.guid,
    ContactID: global.ccUserInfo.contactID, //$contactIDField.val(),
    AccountID: global.ccUserInfo.accountID, //$accountIDField.val(),
    IsRescheduling: global.ccUserInfo.reschedulingAppointment,
    AppointmentId: global.ccUserInfo.appointment, //$("#edit-submitted-step-choose-date-select-a-time").val(),
    CenterId: global.ccUserInfo.centerID, //$centerIDField.val(),
    isS20Active: global.ccUserInfo.isS20Active,
    FewQuestions: global.ccUserInfo.fewQuestions,
    FirstName: global.ccUserInfo.firstName, //$firstNameField.val(),
    LastName: global.ccUserInfo.lastName, //$lastNameField.val(),
    Phone: global.ccUserInfo.phone, //helpers.format_phonenumber( $phonenumberfield.val(), null, 'US' ),
    Address: global.ccUserInfo.address, //$streetAddressField.val() + " " + addressApt,
    City: global.ccUserInfo.city, //$cityField.val(),
    State: global.ccUserInfo.state, //$stateField.val(),
    Zip: global.ccUserInfo.zipcode, //($zipcodeField.val() || $initialZipCodeField.val() )
    testing: global.ccUserInfo.testing, //($zipcodeField.val() || $initialZipCodeField.val() )
    trans_id: global.ccUserInfo.trans_id,
    page: global.ccUserInfo.page,
    referral_id: global.ccUserInfo.referral_id,
    GACLIENTID: (global.ga != 'undefined' && window.ga != undefined) ? global.ga.clientId : '',
    GAUSERID: (global.ga != 'undefined' && window.ga != undefined) ? global.ga.userId : '',
    GATRACKID: (global.ga != 'undefined' && window.ga != undefined) ? global.ga.trackingId : '',
    GCLID: (global.ga != 'undefined' && window.ga != undefined) ? global.ga.trackingId : '',
    securityToken : global.securityToken,
    leadSegment: global.ccUserInfo.leadSegment,
    leadSegment5: global.ccUserInfo.leadSegment5,
    appointmentHtmlInfo: global.ccUserInfo.appointmentHtmlInfo,
    model2PilotCenter: global.ccUserInfo.model2PilotCenter,
    appointmentTimestring: global.ccUserInfo.appointmentDateTimeStringStart,
  };
  if( Cookie.get('userId') ){
    leadData.GAUSERID = Cookie.get('userId');
  }
  if( Cookie.get('cc-gclid') ){
    leadData.GCLID = Cookie.get('cc-gclid');
  }
  if( Cookie.get('_ga')){
    leadData.GACLIENTID = Cookie.get('_ga').substring(6);
  }
  var parsed = queryString.parse(location.search);
  if(parsed.model2testing !== undefined){
    leadData.model2testing = true;
  }
  var testLeadSegment = parsed.testLeadSegment;
  if(testLeadSegment !== undefined){
    leadData.leadSegment = testLeadSegment;
  }

  if (global.ccUserInfo.attemptsAtSubmission === 1) {
    $.ajax({
      url: ajaxurl,
      data: leadData,
      cache: false,
      dataType: 'json',
      type: 'POST',
      success(result) {
        if (result.success === true) {
          window[`gf_submitting_${formID}`] = true;
          $('form.schedule-consultation-form').submit();
          hideWaitingIcon();

        } else if (result.message === 'No appts') {
          // console.log('Appointment was taken:');
          $('#webform-messages').html('<div class="alert alert-danger" role="alert">Sorry, that appointment time was just taken by someone else. Please try a different time, or call '+callCenterNumber+' for assistance.</div>');

          var scrollToPosition = $('.gform_wrapper form').offset().top - $('.banner').height();
          $('html, body').animate({
            scrollTop: scrollToPosition,
          }, 250);

          global.dataLayer.push({ 'error_data': result });
          global.dataLayer.push({ 'error_message': 'Appointment was taken' });
          global.dataLayer.push({ 'event': 'ajax-error' });

          global.dataLayer.push({ 'appointmentnotavailable': true });
          global.dataLayer.push({ 'event': 'Appointment Not Available' });

          submitButton.prop('disabled', false);
          hideWaitingIcon();
        } else {
          // console.log('Server Error validating appointment:');
          $('#webform-messages').html('<div class="alert alert-danger" role="alert">Sorry, there was a problem scheduling your appointment. Try again later, or call '+callCenterNumber+' for assistance.</div>');

          global.dataLayer.push({ 'error_data': result });
          global.dataLayer.push({ 'error_message': 'Server Error validating appointment' });
          global.dataLayer.push({ 'event': 'ajax-error' });
          submitButton.prop('disabled', false);
        }

        if(result == 'no auth token'){
          $('#webform-messages').html('<div class="alert alert-danger" role="alert">Sorry, there was a problem scheduling your appointment. Please try again later, or call ' + callCenterNumber + ' for assistance.</div>');
        }

        $('.gform_button[type="submit"]').prop('disabled', false);
        hideWaitingIcon();

      },
      error(result) {
        $('#webform-messages').html('<div class="alert alert-danger" role="alert">Sorry, there was a problem scheduling your appointment. Try again later, or call '+callCenterNumber+' for assistance.</div>');

        global.dataLayer.push({ 'error_data': result });
        global.dataLayer.push({ 'error_message': 'Server Error validating appointment' });
        global.dataLayer.push({ 'event': 'ajax-error' });
        $('.gform_button[type="submit"]').prop('disabled', false);
        // consultForm.showMessageBox();
        // submitButton.slideDown();
        // console.log('Ajax Error validating appointment.', 'Result: ', result, 'Error: ', error);
        submitButton.prop('disabled', false);
        hideWaitingIcon();
      },
    });
  }

  return false;
}
